<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.condominium.newCondominium") }}
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form class="form-container">
          <b-row>
            <b-col class="col-8 pr-1">
              <form-input
                v-model="condominium.name"
                label="requiredField.condominiumName"
                veeName="condominiumName"
                :veeRules="{ required: true }"
              />
            </b-col>

            <b-col class="col-4 pl-1">
              <form-input
                v-model="condominium.unitsNum"
                label="requiredField.unitsNum"
                veeName="unitsNum"
                :veeRules="{ required: true, integer: true }"
              />
            </b-col>
          </b-row>
          <google-place-search
            v-model="searchAddress"
            label="requiredField.address"
            veeName="address"
            :address="condominium.address"
            :veeRules="{ required: true }"
            @setPlaceInfo="setPlaceInfo"
            @reset="resetAddressDetails"
          />
          <b-row v-if="hasAddressInfo" class="no-gutters">
            <b-col class="col-6 pr-1">
              <form-input
                v-model="condominium.zip"
                label="requiredField.cap"
                veeName="cap"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-6 pl-1">
              <form-input
                v-model="condominium.province"
                label="requiredField.province"
                veeName="province"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-6 pr-1">
              <form-input
                v-model="condominium.city"
                label="requiredField.city"
                veeName="city"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-6 pl-1">
              <form-input
                v-model="condominium.streetNumber"
                label="requiredField.streetNumber"
                veeName="streetNumber"
                :veeRules="{ required: true }"
              />
            </b-col>
          </b-row>
        </b-form>
        <hr class="my-3 mx-n3" />
        <b-row class="no-gutters justify-content-end">
          <b-button variant="outline-dark" class="mr-3" @click="closeModal">
            {{ $t("button.cancel") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            :disabled="invalid"
            @click="createCondominium"
          >
            {{ $t("button.insert") }}
          </b-button>
        </b-row>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import authMixin from "./../../mixins/authMixin";

import FormInput from "../Base/BaseFormInput";
import GooglePlaceSearch from "./../Google/GooglePlaceSearch";
export default {
  components: {
    GooglePlaceSearch,
    FormInput
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  mixins: [authMixin],
  data() {
    return {
      condominium: {
        name: "",
        unitsNum: null,
        address: "",
        zip: "",
        province: "",
        city: "",
        streetNumber: "",
        country: "",
        countryCode: "",
        latitude: 0,
        longitude: 0,
        placeId: "",
        status: "pro-hold",
        role: "administrator"
      },
      searchAddress: ""
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set() {
        this.closeModal();
      }
    },
    hasAddressInfo() {
      return this.condominium.address.length > 0;
    }
  },
  methods: {
    resetForm() {
      this.searchAddress = "";
      this.condominium.name = "";
      this.condominium.unitsNum = null;
      this.resetAddressDetails();
    },
    resetAddressDetails() {
      this.condominium.address = "";
      this.condominium.zip = "";
      this.condominium.province = "";
      this.condominium.city = "";
      this.condominium.streetNumber = "";
      this.condominium.country = "";
      this.condominium.countryCode = "";
      this.condominium.latitude = 0;
      this.condominium.longitude = 0;
      this.condominium.placeId = "";
    },
    closeModal() {
      this.$emit("close");
    },
    setPlaceInfo(place) {
      this.condominium.address = place.streetName;
      this.searchAddress = this.condominium.address;

      this.condominium.zip = place.postalCode;
      this.condominium.province = place.province;
      this.condominium.city = place.city;
      this.condominium.streetNumber = place.streetNumber;
      this.condominium.country = place.country;
      this.condominium.countryCode = place.countryShort;
      this.condominium.latitude = parseFloat(place.lat);
      this.condominium.longitude = parseFloat(place.lng);
      this.condominium.placeId = place.placeId;
    },
    async createCondominium() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        if (this.$_authUserIsDomi) {
          this.condominium.status = "free";
        }
        await this.$store.dispatch(
          "condominium/createCondominium",
          this.condominium
        );
        await this.$store.dispatch("condominium/retrieveCondominiums");
        await this.$store.dispatch("condominium/retrieveCondominiumsByFilters");
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-container {
  min-height: 400px;
}
</style>
