import { render, staticRenderFns } from "./AssignCondominiumModal.vue?vue&type=template&id=6044d727&scoped=true&"
import script from "./AssignCondominiumModal.vue?vue&type=script&lang=js&"
export * from "./AssignCondominiumModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6044d727",
  null
  
)

export default component.exports