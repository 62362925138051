<template>
  <b-modal
    v-model="_state"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="initForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ `${$t("toActivate")} - ` }}
        <span class="font-weight-bold">{{ currentCondominium.name }}</span>
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <b-form>
          <b-row class="no-gutters ml-n3">
            <b-col class="col-12 col-md-6 pl-3">
              <datepicker
                v-model="currentCondominium.expiration"
                label="requiredField.expiration"
                veeName="expiration"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="currentCondominium.unitsNum"
                label="requiredField.unitsNum"
                veeName="unitsNum"
                :veeRules="{ required: true, integer: true }"
              />
            </b-col>
          </b-row>
          <hr class="my-3 mx-n3" />
          <b-row class="no-gutters justify-content-end">
            <b-button
              variant="outline-dark"
              class="mr-1"
              @click="setModalState(false)"
            >
              {{ $t("button.cancel") }}
            </b-button>
            <b-button
              class="text-white"
              variant="primary"
              :disabled="invalid"
              @click="activateCondominium"
            >
              {{ $t("button.saveChanges") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import FormInput from "../Base/BaseFormInput";
import Datepicker from "../Base/BaseDatepicker";
export default {
  components: {
    FormInput,
    Datepicker
  },
  props: {
    state: {
      type: Boolean,
      default: false
    },
    condominium: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      currentCondominium: null
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    }
  },
  methods: {
    initForm() {
      this.currentCondominium = {
        _id: this.condominium._id,
        name: this.condominium.name,
        address: this.condominium.address,
        city: this.condominium.city,
        province: this.condominium.province,
        zip: this.condominium.zip,
        unitsNum: this.condominium.unitsNum,
        expiration: ""
      };
    },
    setModalState(value) {
      this.$emit("update:state", value);
    },
    async activateCondominium() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("condominium/updateCondominium", {
          condominiumId: this.currentCondominium._id,
          payload: {
            name: this.currentCondominium.name,
            address: this.currentCondominium.address,
            city: this.currentCondominium.city,
            province: this.currentCondominium.province,
            zip: this.currentCondominium.zip,
            unitsNum: this.currentCondominium.unitsNum,
            expiration: `${this.currentCondominium.expiration}T00:00:00Z`,
            status: "pro"
          }
        });

        await this.$store.dispatch("condominium/retrieveCondominiums");
        await this.$store.dispatch("condominium/retrieveCondominiumsByFilters");

        this.setModalState(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style></style>
