<template>
  <b-table
    :fields="fields"
    :items="condominiums"
    :sort-by.sync="_sortBy"
    :sort-desc.sync="_sortDesc"
    :responsive="true"
    outlined
    hover
    show-empty
    small
  >
    <template #empty>
      <h6 class="text-center text-italic text-uppercase">
        {{ $t("page.condominium.emptyList") }}
      </h6>
    </template>
    <template #head()="data">
      <div
        :class="{ clickable: data.field.sort }"
        @click="data.field.sort && setSort(data.column)"
      >
        {{ $t(data.label) }}
        <svg
          v-if="data.column === _sortBy"
          viewBox="0 0 16 16"
          class="sort-icon"
        >
          <path fill-rule="evenodd" :d="sortIcon"></path>
        </svg>
      </div>
    </template>
    <template #cell(name)="data" style="width: 20%">
      <div>
        <svg viewBox="0 0 16 16" class="list-item-icon mr-1">
          <path
            v-for="(icon, k) in condominiumIcon"
            :key="k"
            fill-rule="evenodd"
            :d="icon"
          ></path>
        </svg>
        <span class="font-weight-bold">
          {{ data.value }}
        </span>
      </div>
    </template>
    <template #cell(address)="data">
      {{ data.value }}
    </template>
    <template #cell(expiration)="data">
      <span class="text-capitalize">
        {{ data.value | date("DD MMMM YYYY") }}
      </span>
    </template>
    <template #cell(unitsNum)="data">
      {{ data.value }}
    </template>
    <template #cell(administrator)="data">
      {{ data.value }}
    </template>
    <template #cell(role)="data">
      {{ $is1Board ? data.value : $t(`userRoles.${data.value}`) }}
    </template>
    <template #cell(actions)="data">
      <b-row class="no-gutters justify-content-end flex-nowrap">
        <b-col v-for="(action, index) in actions" :key="index" class="col-auto">
          <b-button
            size="sm"
            :variant="action.color || 'secondary'"
            class="ml-1 text-white"
            @click="action.callbackFunc(data.item.id)"
          >
            {{ $t(action.label) }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-table>
</template>

<script>
import { appMixin } from "../../mixins";
export default {
  props: {
    condominiums: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  mixins: [appMixin],
  data() {
    return {
      condominiumIcon: [
        "M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z",
        "M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
      ]
    };
  },
  computed: {
    sortIcon() {
      return this._sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    _sortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("update:sortBy", value);
      }
    },
    _sortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("update:sortDesc", value);
      }
    }
  },
  methods: {
    setSort(newSortBy) {
      if (this._sortBy !== newSortBy) {
        this._sortBy = newSortBy;
        this._sortDesc = false;
      } else {
        this._sortDesc = !this._sortDesc;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list-item-icon {
  width: 20px;
}
.sort-icon {
  width: 20px;
}
</style>
